@import '~src/css/vars.scss';
@import '~src/css/mixins.scss';

.notCustomerYet {
    a {
        color: $primary-dark-green;
        font-family: $font-body;
    }
}

.loginSection {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 2.4rem;
    text-align: center;

    h1 {
        color: $primary-off-white;
    }

    h2 {
        color: $primary-dark-green;
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 50px;
    }

    .form {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        width: 80%;
        margin: 3rem;
        max-width: 500px;

        input[name='email'],
        input[name='postcode'] {
            width: 100%;
            border: 1px $primary-dark-green solid;
            border-radius: 25px;
            color: $primary-dark-green;
        }
    }

    .bottomImage {
        display: none;
    }

    @media (min-width: 1200px) {
        .bottomImage {
            bottom: 0;
            display: block;
            left: 62%;
            position: fixed;
            transform: translateX(-50%);
            width: 1200px;
            z-index: -1;
        }
    }
}

.buttonContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.authFormErrorBar {
    max-width: 23rem;
}

.modalContent {
    text-align: center;
    padding: 2rem 2rem 1rem;

    .modalTitle {
        @include h2Variant;
    }
    .description {
        padding: 1rem;
        letter-spacing: -0.64px;
        color: $primary-dark-green;

        .packageLink {
            margin: 1rem;
            display: inline-block;
            a {
                padding: 0 0.3rem;
            }
        }
    }
}

@media (min-width: $breakpoint-mobile) {
    .form {
        width: 60%;
    }
}

.confirmBtn {
    margin: 0 auto 4rem;
    width: 17rem;
    justify-content: center;
}
