@mixin rainbow-header {
    background: linear-gradient(
        60deg,
        $yellow 0%,
        $red 40%,
        $purple 100%
    ); // sass-lint:disable-line property-sort-order has to be in this order otherwise browsers render incorrectly
    -webkit-background-clip: text; // sass-lint:disable-line property-sort-order, no-vendor-prefixes has to be in this order otherwise browsers render incorrectly
    font-size: 50px;
    font-weight: 600;
    line-height: 50px;
    margin-bottom: 15px;
    padding: 0;
    -webkit-text-fill-color: transparent; // sass-lint:disable-line property-sort-order, no-vendor-prefixes has to be in this order otherwise browsers render incorrectly
}

@mixin background-opacity($color, $opacity) {
    background-color: rgba($color, $opacity);
}

@mixin h1 {
    font-family: $font-heading;
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.08rem;
    line-height: 95%;

    @media (min-width: $breakpoint-desktop) {
        font-size: 8rem;
        letter-spacing: -0.16rem;
        line-height: 120%;
    }
}

@mixin h2 {
    font-family: $font-heading;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.024rem;
    line-height: 120%;

    @media (min-width: $breakpoint-desktop) {
        font-size: 4rem;
        letter-spacing: 0.04rem;
    }
}

@mixin h2Variant {
    font-family: $font-heading;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.48;
    line-height: 95%;

    @media (min-width: $breakpoint-mobile) {
        font-size: 2.5rem;
        letter-spacing: 0.04rem;
    }
}

@mixin h3 {
    font-family: $font-heading;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.024rem;

    @media (min-width: $breakpoint-desktop) {
        font-size: 2.4rem;
        letter-spacing: -0.024rem;
    }
}

@mixin h4 {
    font-family: $font-heading;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.024rem;

    @media (min-width: $breakpoint-desktop) {
        font-size: 2rem;
        letter-spacing: -0.02rem;
    }
}

@mixin h5 {
    font-family: $font-heading;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 95%;
    letter-spacing: -0.24px;

    @media (min-width: $breakpoint-desktop) {
        font-size: 1.5rem;
        letter-spacing: -0.02rem;
    }
}

@mixin bodytext {
    font-family: $font-body;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.056rem;
    line-height: 120%;

    @media (min-width: $breakpoint-desktop) {
        font-size: 1rem;
        letter-spacing: -0.064rem;
    }
}

@mixin normal_border {
    border: solid 3px rgba($primary-dark-green, 0.1);
}
