@import 'src/css/vars.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.checkbox {
    margin-right: 0.5rem;
}

.checkboxContainer {
    align-items: flex-start;
    display: flex;
    font-size: 12px;
    margin-bottom: 1rem;
    margin-top: 1.5em;
}

.submitContainer {
    padding: 0 2rem;
}

.errorText {
    color: $red-error;
    margin-bottom: 20px;
}

.submitButton {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-top: 20px;
}
