@import '~src/css/vars.scss';

$modalPadding: 20px;
$modalMargin: 20px;

.modal {
    text-align: center;

    h2,
    p {
        padding-bottom: $modalPadding;
    }

    button {
        display: block;
        margin: 0 auto;
    }
}
