@import '~src/css/vars.scss';

.rebrandBody {
    background-image: none;
    background-color: $primary-off-white;
    min-height: 100vh;

    .input {
        border: 1px solid $secondary-green-900;
    }
}

.defaultBody {
    background-color: $primary-off-white;
}
