@import 'src/css/vars.scss';

.company_name {
    padding: 2rem;
    font-family: elza-condensed, sans-serif;

    .input {
        > label {
            margin-top: 20px;
            font-weight: 500;
            font-size: 19px;
            font-style: inherit;
        }
    }

    .field {
        display: flex;
        flex-direction: column;
        label {
            margin-top: 20px;
            font-weight: 500;
            font-size: 19px;
        }

        input {
            border-radius: 25px;
            border: 1px solid #024048;
            width: auto;
            margin-top: 10px;
        }
    }
    .vat_container {
        display: flex;
        margin-top: 20px;
        h3 {
            font-size: 19px;
            font-weight: 500;
            margin-bottom: 0.2rem;
            margin-right: 1rem;
            align-self: center;
        }
    }
}
